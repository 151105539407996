import React, { useContext, useEffect, useState } from "react";

import { CartContext } from "../../cartContext";
import ImageSm from "../ImageSm";
import { Link } from "gatsby";

function SingleProdCarousel({ product }) {
  const { inCart } = useContext(CartContext);
  const hasOptions = product?.productsOptions.length > 0;
  const isInCart = inCart(product?.id);
  const slugish = require("../../../slugify");
  const poster = product?.poster;
  const getLowestPrice = (product) => {
    let lowestPrice = 0;
    let prices = [];
    if (product?.productsOptions.length > 0) {
      product?.productsOptions.map((p) => {
        p.option.productsOptions.map((s) => {
          let priPerGuest = s.subOption.price / s.subOption.guests;
          prices.push(priPerGuest);
        });
      });
    }
    lowestPrice = prices.sort((a, b) => a - b)[0];
    return lowestPrice.toFixed(2);
  };
  let price = `${product?.price}€`;
  if (hasOptions) price = `ab ${getLowestPrice(product)}€ pro Person`;
  if (product?.categoryByCategory.id === 4)
    price = `ab ${product?.price + 12}€`;

  return (
    <div className="mx-1">
      <Link
        to={`/produkte/${product?.categoryByCategory.slug}/${slugish(
          product?.name
        )}`}
      >
        <div className="card product-card-alt card-static pb-1 my-1 box-shadow">
          {isInCart ? (
            <span className="badge badge-primary badge-shadow m-2">
              In Cart
            </span>
          ) : null}
          <span
            className=" badge-right badge badge-primary badge-shadow m-2"
            data-bs-toggle="popover"
            data-bs-trigger="hover focus"
            data-bs-content="Disabled popover"
            title={`${
              product?.id > 9999
                ? null
                : product?.id > 1999 && product?.id < 9999
                ? " Lieferung in 48 Std."
                : "Lieferung in 2 Std."
            }}`}
          >
            {product?.id > 1999
              ? product?.id < 9999
                ? "48 Std."
                : ""
              : "2 Std."}
          </span>
          <div className="card-img-top d-block overflow-hidden">
            {/* <img alt="products" src={`img/${product?.image}`} /> */}
            <ImageSm filename={poster} alt={product?.title} />
          </div>
          <div className="card-body py-2">
            {product?.name}
            <h3 className="product-title widget-list-item font-size-sm">
              {product?.subcatBysubCat
                ? product?.subcatBysubCat.name
                : product?.categoryByCategory.name}
            </h3>
            <div className="product-price">
              <span className="text-accent">{price}</span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default SingleProdCarousel;
