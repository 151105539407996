import React, { useEffect, useState } from "react";

function Sorting({ subCategories, handleSelectSubcat }) {
  const [selected, setSelected] = useState(-1);

  let subArray = [];
  subCategories.forEach((value, key) => {
    subArray.push({ key, value });
  });
  useEffect(() => {
    selected !== -1 ? handleSelectSubcat(subArray[selected]) : handleSelectSubcat(subArray);
    return () => {};
  }, [selected]);
  return (
    <section className="d-md-flex justify-content-between align-items-center mt-2 mb-3 pb-2 sticky">
      <div className="d-flex align-items-center ">
        <div className="d-none d-sm-block py-2 font-size-sm text-muted text-nowrap mr-2"></div>
        <ul className="nav nav-tabs font-size-sm mb-0">
          <li key="all" className="nav-item">
            <span
              className={selected === -1 ? "nav-link active" : "nav-link"}
              href="#"
              onClick={() => setSelected(-1)}
              role="button"
            >
              All
            </span>
          </li>
          {subArray.map((subCat, i) => {
            return (
              <li key={i} className="nav-item">
                <span
                  className={selected === i ? "nav-link active" : "nav-link"}
                  href="#"
                  onClick={() => setSelected(i)}
                  role="button"
                >
                  {subCat.value}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
}

export default Sorting;
