import React, { useEffect, useState } from "react";

import { Breadcrumb } from "gatsby-plugin-breadcrumb";
import HowItWorks from "../components/HowItWorks-punschbox";
import HowItWorksC from "../components/HowItWorks-crepes";
import SingleProdCarousel from "../components/carousels/SingleProdCarousel";
import Sorting from "../components/Sorting";
import { graphql } from "gatsby";

const Category = ({ data, pageContext }) => {
  const [subCat, setsubCat] = useState(-1);
  const [subProds, setsubProds] = useState([]);
  const {
    breadcrumb: { crumbs },
  } = pageContext;
  const { products } = data?.hasura?.categories_by_pk;
  const slug = data?.hasura?.categories_by_pk.name;

  const category = data?.hasura?.categories_by_pk;
  let subCategories = new Map();
  products.map((product) => {
    return product?.subcatBysubCat
      ? subCategories.set(
          product?.subcatBysubCat.id,
          product?.subcatBysubCat.name
        )
      : null;
  });
  useEffect(() => {
    // console.log(products);
    if (subCat === -1) {
      setsubProds(products);
    } else {
      let tmp = products.filter((item) => item.subcatBysubCat.id === subCat);
      setsubProds(tmp);
    }
    return () => {};
  }, [subCat]);

  const handleSelectSubcat = (value) => {
    value.key ? setsubCat(value.key) : setsubCat(-1);
  };

  return (
    <React.Fragment>
      <div className="page-title-overlap bg-accent pt-2">
        <div className="container d-lg-flex justify-content-between py-4 py-lg-3">
          <div className="pl-lg-2 order-lg-2 mb-0 mb-lg-0 pt-lg-2 breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center">
            <Breadcrumb crumbs={crumbs} crumbSeparator="" crumbLabel={slug} />
          </div>
        </div>
      </div>
      <section className="container p-0 p-sm-4 ">
        <div className="card px-lg-2 border-0 box-shadow-lg">
          <div className="card-body px-2 pt-4 pb-4">
            <h2 className="h3 text-center">{slug}</h2>
            <p className="text-muted text-center">
              {data?.hasura?.categories_by_pk.description}
            </p>
          </div>
        </div>
        {category.id === 1 && <HowItWorksC />}
        {category.id === 4 && <HowItWorks />}
        {subCategories.size > 0 ? (
          <Sorting
            subCategories={subCategories}
            handleSelectSubcat={handleSelectSubcat}
          />
        ) : null}
        <div className="row mt-3">
          {subProds.map((product, pid) => (
            <div
              key={`prod${pid}`}
              className={
                "col-xl-3 col-lg-3 col-md-4 col-sm-6 px-2 mt-sm-2 mb-3"
              }
            >
              <SingleProdCarousel key={product?.id} product={product} />
            </div>
          ))}
        </div>
      </section>
    </React.Fragment>
  );
};

export default Category;

export const query = graphql`
  query SingeCategory($id: Int!) {
    hasura {
      categories_by_pk(id: $id) {
        id
        name
        description
        longtext
        slug
        catchphrase
        image
        active
        products(
          order_by: { subcatBysubCat: { name: asc }, name: asc }
          where: { categoryByCategory: { active: { _eq: true } } }
        ) {
          name
          id
          price
          poster
          productsOptions(distinct_on: option_id) {
            subOption {
              guests
              price
              name
            }
            option {
              id
              name
              productsOptions(order_by: { subOption: { price: asc } }) {
                subOption {
                  id
                  price
                  guests
                }
              }
            }
          }
          categoryByCategory {
            id
            slug
            name
          }
          subcatBysubCat {
            id
            name
            slug
            icon
          }
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const OGIMAGE = `https://www.punsch-mobil.at/img/${data?.hasura?.categories_by_pk.image}`;
  const TITLE = `Punsch Mobil - ${data?.hasura?.categories_by_pk.name}`;
  const DESCRIPTION = `${data?.hasura?.categories_by_pk.catchphrase}`;
  const PAGEURL = `https://www.punsch-mobil.at/produkte/${data?.hasura?.categories_by_pk.slug}`;
  return (
    <>
      <title>{TITLE}</title>
      <link rel="canonical" href={PAGEURL} />
      <link
        rel="icon"
        type="image/png"
        href="https://www.punsch-mobil.at/favicon.png"
      />
      <meta
        name="description"
        content={data?.hasura?.categories_by_pk.catchphrase}
      />
      <meta
        name="keywords"
        content={[
          "punsch",
          "crepes",
          "lieferung",
          "wien",
          "punsch mobil",
          "punsch lieferservice",
          "punsch zustellung",
        ]}
      />
      <meta property="og:url" content={PAGEURL} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={TITLE} />
      <meta property="og:description" content={DESCRIPTION} />
      <meta property="og:image" content={OGIMAGE} />
      <meta name="twitter:card" content="summary" />
      <meta
        name="twitter:site"
        content={"lacreperiemobile" && `@lacreperiemobile`}
      />
      <meta name="twitter:title" content={TITLE} />
      <meta name="twitter:description" content={DESCRIPTION} />
      <meta name="twitter:image" content={OGIMAGE} />
    </>
  );
};
