import Img from "gatsby-image";
import React from "react";
import { StaticQuery } from "gatsby";
import { graphql } from "gatsby";

const ImageSm = (props) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 350) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data?.images.edges.find((n) => {
        return n.node.relativePath.includes(props.filename);
      });
      if (!image) {
        return null;
      }

      return (
        <div className="image-container">
          <Img
            className={props.className}
            alt={props.alt}
            fluid={image.node.childImageSharp.fluid}
          ></Img>
          {props.caption && (
            <span className="image-caption rounded">{props.caption}</span>
          )}
        </div>
      );
    }}
  />
);

export default ImageSm;
