import ImageFixed from "./ImageFixed";
import React from "react";

const HowItWorks = () => {
  return (
    <section className="pt-1 px-2 px-sm-4 mt-2 mt-sm-4 w-100">
      {/* <h2 className="h3 text-center mb-grid-gutter pt-2">How it works?</h2> */}
      <div className="d-flex flex-column flex-md-row  rounded-lg justify-content-center">
        <div className="d-flex flex-column align-items-fill justify-content-center card box-shadow my-1 mx-sm-1 ">
          <div className="p-1 ">
            <div className="d-flex align-items-center mx-auto  px-2 " style={{ maxWidth: 362 + "px" }}>
              <div className="display-2 font-weight-normal  mx-3">
                <i className="czi czi-basket-alt h3 mt-2 mb-4 text-primary"></i>
              </div>
              <div className="pl-2">
                {/* <img className="d-block my-2" src="imgdiv/spacer.png" alt="Order online" /> */}
                <span className="pt-1">Heiße Getränke zum selber zapfen mit Häferl und allem was Du brauchst</span>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column align-items-stretch justify-content-center card box-shadow my-1 mx-sm-1">
          <div className="">
            <div className="d-flex align-items-center mx-auto px-2" style={{ maxWidth: 362 + "px" }}>
              <div className="display-2 font-weight-normal mx-3">
                <i className="czi czi-juice h3 mt-2 mb-4 text-primary"></i>
              </div>
              <div className="pl-2">
                {/* <img className="d-block my-2" src="imgdiv/spacer.png" alt="spacer" /> */}
                <span className="pt-1">Wähle aus 18 Sorten hausgemachtem Punsch und Glühwein</span>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column align-items-stretch justify-content-center card box-shadow my-1 mx-sm-1">
          <div className="">
            <div className="d-flex align-items-center mx-auto px-2" style={{ maxWidth: 362 + "px" }}>
              <div className="display-2 font-weight-normal border-color mx-3">
                <i className="czi czi-delivery h3 mt-2 mb-4 text-primary"></i>
              </div>
              <div className="pl-2">
                {/* <img className="d-block my-2" src="imgdiv/spacer.png" width="72" alt="spacer" /> */}
                <span className="pt-1">Am nächsten Tag holen wir alles wieder ab</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
