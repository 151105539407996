const slugify = (str) =>
  str
    .toLowerCase()
    .replace(/[ôöòóœøō]+/g, "o")
    .replace(/[àáâäæãåā]+/g, "a")
    .replace(/[ûüùúū]+/g, "u")
    .replace(/[èéêëē]+/g, "e")
    .replace(/[ß]+/g, "ss")

    .replace(/[^a-z0-9]+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "") || "untitled";

const slugish = (str) => {
  return decodeURI(slugify(str));
};

module.exports = slugish;
